import { styled } from '@mui/material/styles';
import { OldTextField } from 'views/reservation-path-module/common/components';

const SignTextField = styled(OldTextField)(({ theme }) => ({
  '&, &:hover': {
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent',

    '&.Mui-focused': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.secondary.light,
  },
}));

export { SignTextField };
