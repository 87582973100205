import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const LightBulb = (props) => (
  <SvgIcon {...props} viewBox="0 0 15 19">
    <path
      d="M8.00032 0C7.54032 0 7.07032 0.0400001 6.60032 0.14C3.84032 0.67 1.64032 2.9 1.12032 5.66C0.640315 8.27 1.60032 10.67 3.34032 12.22C3.77032 12.6 4.00032 13.13 4.00032 13.69V16C4.00032 17.1 4.90032 18 6.00032 18H6.28032C6.63032 18.6 7.26032 19 8.00032 19C8.74032 19 9.38032 18.6 9.72032 18H10.0003C11.1003 18 12.0003 17.1 12.0003 16V13.69C12.0003 13.14 12.2203 12.6 12.6403 12.23C14.0903 10.95 15.0003 9.08 15.0003 7C15.0003 3.13 11.8703 0 8.00032 0ZM10.0003 14H6.00032V13H10.0003V14ZM6.00032 16V15H10.0003V16H6.00032ZM11.3103 10.74C11.2203 10.82 11.1503 10.92 11.0703 11H4.92032C4.84032 10.91 4.77032 10.81 4.68032 10.73C3.36032 9.55 2.77032 7.79 3.09032 6.03C3.45032 4.09 5.05032 2.48 6.98032 2.1C7.32032 2.03 7.66032 2 8.00032 2C10.7603 2 13.0003 4.24 13.0003 7C13.0003 8.43 12.3903 9.79 11.3103 10.74Z"
      fill="#F3E05E"
    />
    <path d="M8.50049 8H7.50049V11H8.50049V8Z" fill="#F3E05E" />
    <path
      d="M6.37967 5.87354L5.67285 6.58105L7.79331 8.7036L8.50013 7.99608L6.37967 5.87354Z"
      fill="#F3E05E"
    />
    <path
      d="M7.5014 8.0053L8.2085 8.7124L10.3298 6.5911L9.6227 5.884L7.5014 8.0053Z"
      fill="#F3E05E"
    />
  </SvgIcon>
);

export default LightBulb;
