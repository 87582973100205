import React from 'react';
import theme from 'lib/config/theme';
import { TextField, ThemeProvider } from '@mui/material';

const LayoutTextField = (props) => {
  const { children, ...restOfProps } = props;
  return (
    <ThemeProvider theme={theme}>
      {children ? (
        <TextField {...restOfProps}>{children}</TextField>
      ) : (
        <TextField {...restOfProps} />
      )}
    </ThemeProvider>
  );
};

export default LayoutTextField;
