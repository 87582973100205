import React from 'react';
import { styled } from '@mui/material/styles';
import LightBulb from 'views/common/components/UI/Icons/LightBulb';

const PREFIX = 'LightBulbHint';

const classes = {
  root: `${PREFIX}-root`,
  svgHolder: `${PREFIX}-svgHolder`,
  hintText: `${PREFIX}-hintText`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    color: theme.palette.grey[300],
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(0.5, 0, 0.5, 1.5),
    borderLeft: `1px solid ${theme.palette.warning.main}`,
  },

  [`& .${classes.svgHolder}`]: {
    marginRight: theme.spacing(1.5),
    display: 'inline-flex',
  },

  [`& .${classes.hintText}`]: {
    fontStyle: 'italic',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey['400'],
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '& span': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));

const LightBulbHint = (props) => {
  const { children, className } = props;

  return (
    <Root className={`${classes.root} ${className}`}>
      <div className={classes.svgHolder}>
        <LightBulb />
      </div>
      <div className={classes.hintText}>{children}</div>
    </Root>
  );
};

export default LightBulbHint;
