import React, { useState } from 'react';
import axios, { API } from 'lib/config/axios';
import { useTranslation } from 'next-i18next';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {
  IconButton, Dialog, Box, Grid, styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/features/ui-slice';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import TextField from 'views/common/components/Logical/Layout/LayoutTextField';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-root': {
    zIndex: [theme.zIndex.modal + 1, '!important'],
  },
  '& .MuiDialog-paper': {
    width: theme.typography.pxToRem(600),
    maxHeight: '90vh',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.main,
  },
  '& .form': {
    marginBottom: theme.spacing(1),
  },
  '& .message': {
    margin: theme.spacing(0.8, 0, 2.5),
  },
  '& .dialogActions': {
    padding: theme.spacing(3),
  },
  '& .closeBtn': {
    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
  },
}));

const ForgotPasswordDialog = (props) => {
  const { onClose, open } = props;
  const { t } = useTranslation();
  const handleErrorResponse = useErrorsHandler();
  const dispatch = useDispatch();
  const authenticityToken = useSelector(
    (state) => state.globalData.appState?.authenticity_token,
  );

  const [errorMsgs, setErrorMsgs] = useState({});

  const [value, setValue] = useState(''); // email value

  const handleSubmit = async () => {
    setErrorMsgs({});
    try {
      const { data } = await axios.post(API.users.forgotPassword, {
        authenticity_token: authenticityToken,
        user: {
          email: value,
        },
      });
      dispatch(
        setSnackbar({
          open: true,
          severity: 'success',
          message: data.message,
        }),
      );
      onClose();
      setValue(''); // reset
    } catch (error) {
      if (error.response && error.response.status && error.response.data) {
        switch (error.response.status) {
          case 406:
            dispatch(
              setSnackbar({
                open: true,
                severity: 'error',
                message: error.response.data.message,
              }),
            );
            if (error.response.data.errors) {
              setErrorMsgs({
                ...error.response.data.errors,
              });
            }
            break;
          default:
            handleErrorResponse(error);
        }
      }
    }
  };

  const handleCancel = () => {
    // when dialog is closed
    onClose();
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <StyledDialog
      onClose={handleCancel}
      id="forgor-password"
      aria-labelledby="currencies-dialog"
      open={open}
      maxWidth="lg"
      keepMounted>
      <DialogContent>
        <Box mb={1}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h3">
                {t('fe_er_common_layout:forgot_password')}
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <IconButton
                onClick={handleCancel}
                aria-label="Cancel"
                color="secondary"
                className="closeBtn"
                size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <form className="form">
          <Typography variant="body2" className="message">
            {t('fe_er_common_layout:forget_password_note')}
          </Typography>
          <TextField
            error={Boolean(errorMsgs.email)}
            label="Email"
            color="secondary"
            fullWidth
            value={value}
            variant="outlined"
            onChange={handleChange}
            helperText={errorMsgs.email}
          />
        </form>
      </DialogContent>
      <DialogActions className="dialogActions">
        <Button onClick={handleCancel} color="secondary">
          {t('fe_er_common_layout:cancel')}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          variant="contained">
          {t('fe_er_common_layout:reset_password')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ForgotPasswordDialog;
